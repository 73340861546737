import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  FormControlLabel,
  Switch,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getEstate } from '../../services/EstateService';
import { Estate } from '../../types/Estate';

const EstateSetup: React.FC = () => {
  const { estateId } = useParams<{ estateId: string }>();
  const [estate, setEstate] = useState<Estate | null>(null);
  const [loading, setLoading] = useState(false);
  const [editableFields, setEditableFields] = useState<{ [key: string]: boolean }>({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchEstateDetails();
  }, [estateId]);

  const fetchEstateDetails = async () => {
    setLoading(true);
    try {
      const response = await getEstate(Number(estateId));
      if (response) {
        setEstate(response);
        const initialEditableState = {
          name: false,
          minVend: false,
          maxVend: false,
          serviceCharge: false,
        };
        setEditableFields(initialEditableState);
      }
    } catch (error) {
      console.error('Error fetching estate details:', error);
      setErrorMessage('Failed to fetch estate details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (!estate) return;

    setLoading(true);
    try {
      await axios.put(`/api/estates/${estate.id}`, estate);
      setSuccessMessage('Estate details saved successfully.');
      navigate('/admin/dashboard/estates');
    } catch (error) {
      console.error('Error saving estate details:', error);
      setErrorMessage('Failed to save estate details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field: string, value: any) => {
    setEstate((prev) => (prev ? { ...prev, [field]: value } : prev));
  };

  const toggleEdit = (field: string) => {
    setEditableFields((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  type ServiceKey = 'powerVend' | 'serviceCharge' | 'waterDeposit' | 'dieselDeposit';
  const handleServiceToggle = (service: ServiceKey) => {
    setEstate((prev) =>
      prev
        ? {
            ...prev,
            services: {
              ...prev.services,
              [service]: !prev.services[service],
            },
          }
        : prev
    );
  };

  if (loading || !estate) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper elevation={3} style={{ padding: '1rem', margin: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        Estate Setup
      </Typography>
      <TextField
        label="Estate Name"
        value={estate.name}
        onChange={(e) => handleChange('name', e.target.value)}
        fullWidth
        margin="normal"
        disabled={!editableFields.name}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => toggleEdit('name')}>
              <EditIcon />
            </IconButton>
          ),
        }}
      />
      <TextField
        label="Minimum Vend (Single Vend)"
        type="number"
        value={estate.settings?.min_vend}
        onChange={(e) => handleChange('minVend', parseFloat(e.target.value))}
        fullWidth
        margin="normal"
        disabled={!editableFields.minVend}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => toggleEdit('minVend')}>
              <EditIcon />
            </IconButton>
          ),
        }}
      />
      <TextField
        label="Maximum Vend (Monthly limit)"
        type="number"
        value={estate.settings?.max_vend}
        onChange={(e) => handleChange('maxVend', parseFloat(e.target.value))}
        fullWidth
        margin="normal"
        disabled={!editableFields.maxVend}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => toggleEdit('maxVend')}>
              <EditIcon />
            </IconButton>
          ),
        }}
      />
      <TextField
        label="Service Charge"
        type="number"
        value={estate.service_charge}
        onChange={(e) => handleChange('serviceCharge', parseFloat(e.target.value))}
        fullWidth
        margin="normal"
        disabled={!editableFields.serviceCharge}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => toggleEdit('serviceCharge')}>
              <EditIcon />
            </IconButton>
          ),
        }}
      />
      <Typography variant="h6" gutterBottom>
        Services
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={estate.services?.powerVend}
            onChange={() => handleServiceToggle('powerVend')}
          />
        }
        label="Power Vend"
      />
      <FormControlLabel
        control={
          <Switch
            checked={estate.services?.serviceCharge}
            onChange={() => handleServiceToggle('serviceCharge')}
          />
        }
        label="Service Charge"
      />
      <FormControlLabel
        control={
          <Switch
            checked={estate.services?.waterDeposit}
            onChange={() => handleServiceToggle('waterDeposit')}
          />
        }
        label="Water Deposit"
      />
      <FormControlLabel
        control={
          <Switch
            checked={estate.services?.dieselDeposit}
            onChange={() => handleServiceToggle('dieselDeposit')}
          />
        }
        label="Diesel Deposit"
      />
      <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={() => navigate('/admin/dashboard/estates')} color="secondary" style={{ marginRight: '0.5rem' }}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </div>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage('')}>
        <Alert severity="success" onClose={() => setSuccessMessage('')}>{successMessage}</Alert>
      </Snackbar>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage('')}>
        <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>
      </Snackbar>
    </Paper>
  );
};

export default EstateSetup;
