import React from 'react';
// import { NativeBaseProvider } from 'native-base';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import AdminLogin from './pages/admin/AdminLogin';
import AdminDashboard from './pages/admin/AdminDashboard';
import ResidentManagement from './pages/admin/ResidentManagement';
import Reports from './pages/admin/Reports';
import EstateManagement from './pages/admin/EstateManagement';
import PendingApprovals from './pages/admin/PendingApprovals';
import EstateSetup from './pages/admin/EstateSetup';
import AddNewEstate from './pages/admin/AddNewEstate';

const App: React.FC = () => {
  return (
    // <NativeBaseProvider>
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin" element={<AdminDashboard />}>
              <Route path="residents" element={<ResidentManagement />} />
              <Route path="newusers" element={<PendingApprovals />} />
              <Route path="reports" element={<Reports />} />
              <Route path="estates" element={<EstateManagement />} />
              <Route path="estates/:estateId" element={<EstateSetup />} />
              <Route path="estates/new" element={<AddNewEstate />} />

            </Route>

            {/* Protected routes */}
            <Route element={<ProtectedRoute />}>
              {/* <Route path="/power-purchase" element={<PowerPurchase />} /> */}
              {/* <Route path="/service-fee" element={<ServiceFee />} /> */}
            </Route>

            {/* Fallback for undefined routes */}
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </AuthProvider>
    // </NativeBaseProvider>
  );
};

export default App;