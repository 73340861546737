import client from "./api";


// Define types for transactions and the API response
interface Transaction {
  id: number;
  category: string;
  amount: number;
  createdAt: string; // Formatted as a human-readable date
}

interface ResidentHistoryResponse {
  allData: Transaction[];
  totalPurchase: string;
}

export const getResidents = async (): Promise<any> => {
  const response = await client.get(`/api/admin/users`);
  console.log('residents fetch', response.data);
  return response.data.data;
};

export const updateResidentStatus = async (id: number, status: string) => {
  const response = await client.put(`/api/residents/${id}/status`, { status });
  return response.data;
};

export const fetchPendingUsers = async(startDate: any|null, endDate: any|null) => {
  try{
    const response = await client.get(`/api/admin/pending-users`, { params: { startDate, endDate } });
    // console.log('fetchPendingUsers', response.data.data);
    return response.data.data;
  }
  catch(error: any){
    console.error(error.message);
  }
  
};

export const residentTransactionHistory = async (
  user_id: number
): Promise<ResidentHistoryResponse | null> => {
  try {
    // Set default date range
    const from = "1970-01-01";
    const to = new Date().toISOString().split("T")[0]; // Today's date in ISO format

    console.log("Fetching transaction history for:", user_id, "from:", from, "to:", to);

    // Validate inputs
    if (!user_id) {
      console.error("Invalid user_id provided.");
      return null;
    }

    // Make the API call
    const txnsResponse = await client.post("/api/payment/all", { user_id, from, to });

    // Extract transactions and purchase data
    const transactionsData = txnsResponse.data?.data?.transactions || [];
    const totalPurchase = txnsResponse.data?.data?.tPurchases || "0";

    console.log("Fetched transactionsData:", transactionsData);

    // Map transactions to a consistent structure
    const allData: Transaction[] = transactionsData.map((item: any) => ({
      id: item.id,
      category: getServiceName(item.category),
      amount: item.amount,
      createdAt: new Date(item.createdAt).toDateString(), // Format the date
    }));

    // Return structured data
    return { allData, totalPurchase };
  } catch (error: any) {
    console.error("Error in residentTransactionHistory:", error.message);
    return null; // Return `null` in case of error
  }
};

  
const getServiceName = (serviceId: number): string => {
  const serviceMap: { [key: number]: string } = {
    0: "Vend",
    1: "Service Fee",
    3: "Diesel Deposit",
  };
  return serviceMap[serviceId] || "Water Deposit"; // Default to "Water Deposit" if serviceId is not in the map
};
