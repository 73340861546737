import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumbs from "../../components/BreadCrumbs";
import BackButton from "../../components/BackButton";

const AddNewEstate: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [estateName, setEstateName] = useState("");
  const [estateAddress, setEstateAddress] = useState("");
  const [services, setServices] = useState({
    powerVend: false,
    serviceCharge: false,
    waterDeposit: false,
    dieselDeposit: false,
  });
  const [minVend, setMinVend] = useState(0);
  const [maxVend, setMaxVend] = useState(0);
  const [serviceChargeAmount, setServiceChargeAmount] = useState(0);
  const [dieselDepositAmount, setDieselDepositAmount] = useState(0);
  const [waterDepositAmount, setWaterDepositAmount] = useState(0);

  const navigate = useNavigate();

  type ServiceType =
    | "powerVend"
    | "serviceCharge"
    | "waterDeposit"
    | "dieselDeposit";

  const handleServiceToggle = (service: ServiceType) => {
    setServices((prev) => ({ ...prev, [service]: !prev[service] }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        name: estateName,
        address: estateAddress,
        services,
        settings: {
          min_vend: services.powerVend ? minVend : undefined,
          max_vend: services.powerVend ? maxVend : undefined,
        },
        service_charge: services.serviceCharge
          ? serviceChargeAmount
          : undefined,
      };
      await axios.post("/api/estates", data);
      navigate("/admin/dashboard/estates");
    } catch (error) {
      console.error("Error saving estate:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <><BackButton />
    <Paper elevation={3} style={{ padding: "1rem", margin: "2rem" }}>
      
      <Breadcrumbs />
      
      <Typography variant="h4" gutterBottom>
        Add New Estate
      </Typography>
      <TextField
        label="Estate Name"
        value={estateName}
        onChange={(e) => setEstateName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Estate Address"
        value={estateAddress}
        onChange={(e) => setEstateAddress(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Typography variant="h6" gutterBottom>
        Services
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={services.powerVend}
            onChange={() => handleServiceToggle("powerVend")}
          />
        }
        label="Power Vend"
      />
      {services.powerVend && (
        <>
          <TextField
            label="Minimum Vend (Single Vend)"
            type="number"
            value={minVend}
            onChange={(e) => setMinVend(parseFloat(e.target.value))}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Maximum Vend (Monthly)"
            type="number"
            value={maxVend}
            onChange={(e) => setMaxVend(parseFloat(e.target.value))}
            fullWidth
            margin="normal"
          />
        </>
      )}
      <FormControlLabel
        control={
          <Switch
            checked={services.serviceCharge}
            onChange={() => handleServiceToggle("serviceCharge")}
          />
        }
        label="Service Charge"
      />
      {services.serviceCharge && (
        <TextField
          label="Service Charge Amount"
          type="number"
          value={serviceChargeAmount}
          onChange={(e) => setServiceChargeAmount(parseFloat(e.target.value))}
          fullWidth
          margin="normal"
        />
      )}
      <FormControlLabel
        control={
          <Switch
            checked={services.waterDeposit}
            onChange={() => handleServiceToggle("waterDeposit")}
          />
        }
        label="Water Deposit"
      />
      {services.waterDeposit && (
        <TextField
          label="Water Deposit Amount"
          type="number"
          value={waterDepositAmount}
          onChange={(e) => setWaterDepositAmount(parseFloat(e.target.value))}
          fullWidth
          margin="normal"
        />
      )}
      <FormControlLabel
        control={
          <Switch
            checked={services.dieselDeposit}
            onChange={() => handleServiceToggle("dieselDeposit")}
          />
        }
        label="Diesel Deposit"
      />
        {services.dieselDeposit && (
            <TextField
            label="Diesel Deposit Amount"
            type="number"
            value={dieselDepositAmount}
            onChange={(e) => setDieselDepositAmount(parseFloat(e.target.value))}
            fullWidth
            margin="normal"
            />
        )}

      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={() => navigate("/admin/dashboard/estates")}
          color="secondary"
          style={{ marginRight: "0.5rem" }}
        >
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </div>
    </Paper></>
  );
};

export default AddNewEstate;
