import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material';
import styled from 'styled-components';

const StyledBreadcrumbs = styled(MUIBreadcrumbs)`
  margin-bottom: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
`;

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <StyledBreadcrumbs aria-label="breadcrumb">
      {/* <StyledLink to="/admin">Dashboard</StyledLink> */}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="textPrimary" key={to}>
            {value}
          </Typography>
        ) : (
          <StyledLink to={to} key={to}>
            {value}
          </StyledLink>
        );
      })}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;