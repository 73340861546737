import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAllEstates } from '../../services/EstateService';
import { Estate } from '../../types/Estate';

const EstateManagement: React.FC = () => {
  const [estates, setEstates] = useState<Estate[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEstates();
  }, []);

  const fetchEstates = async () => {
    setLoading(true);
    try {
      const response = await getAllEstates();
      if (response) {
        setEstates(response);
      }
    } catch (error) {
      console.error('Error fetching estates:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEstateClick = (estateId: number) => {
    console.log('Navigating to estate with ID:', estateId);
    navigate(`/admin/estates/${estateId}`);
  };

  const handleAddEstateClick = () => {
    navigate('/admin/estates/new');
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper elevation={3} style={{ padding: '1rem', margin: '2rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Estate Management
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddEstateClick}>
          Add a New Estate
        </Button>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estates.map((estate) => (
              <TableRow key={estate.id} hover>
                <TableCell>{estate.name}</TableCell>
                <TableCell>{estate.address}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleEstateClick(estate.id)}
                  >
                    Setup
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default EstateManagement;
