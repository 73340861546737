import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import { saveAs } from "file-saver";
import { fetchAllTransactions } from "../../services/TransactionService";
import { Transaction } from "../../types/Transaction";

const Reports = () => {
  const [reports, setReports] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedReports, setSelectedReports] = useState<any[]>([]);
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    action: null,
    targetId: null,
    message: "",
  });
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    fetchReports();
  }, [page, filters]);

  const getCategoryName = (category: number) => {
    switch (category) {
      case 0:
        return "Power Vend";
      case 1:
        return "Service Charge";
      case 3:
        return "Diesel Deposit";
      case 4:
        return "Water Deposit";
      default:
        return "Unknown";
    }
  };

  const fetchReports = async () => {
    setLoading(true);
    try {
      const data = await fetchAllTransactions(page + 1, filters.startDate, filters.endDate);
      setReports(data.transactions);
      setTotalRecords(data.pagination.totalRecords);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id: number) => {
    axios.delete(`/reports/${id}`).then(() => {
      setReports(reports.filter((report) => report.id !== id));
    });
  };

  const handleBulkDelete = () => {
    axios.post("/reports/bulk-delete", { report_ids: selectedReports }).then(() => {
      setReports(reports.filter((report) => !selectedReports.includes(report.id)));
      setSelectedReports([]);
    });
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      setSelectedReports(reports.map((report) => report.id));
    } else {
      setSelectedReports([]);
    }
  };

  const handleSelectReport = (id: number) => {
    setSelectedReports((prev) =>
      prev.includes(id) ? prev.filter((reportId) => reportId !== id) : [...prev, id]
    );
  };

  const openConfirmDialog = (action: string, id: number|null) => {
    const message =
      action === "delete"
        ? "Are you sure you want to delete this report?"
        : "Are you sure you want to delete all selected reports?";
    setConfirmDialog({ open: true, action, targetId: id, message });
  };

  const closeConfirmDialog = () => {
    setConfirmDialog({
      open: false,
      action: null,
      targetId: null,
      message: "",
    });
  };

  const handleConfirmAction = () => {
    const { action, targetId } = confirmDialog;
    if (action === "delete") {
      handleDelete(targetId);
    } else if (action === "bulkDelete") {
      handleBulkDelete();
    }
    closeConfirmDialog();
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const applyFilters = () => {
    setPage(0);
    fetchReports();
  };

  const exportToCSV = () => {
    const csvHeaders = "Title,Date,Status\n";
    const csvContent = reports
      .map(
        (report) =>
          `${report.category},${new Date(report.createdAt).toLocaleDateString()},Successful`
      )
      .join("\n");

    const csvBlob = new Blob([csvHeaders + csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvBlob, "reports.csv");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper elevation={3} style={{ padding: "1rem", margin: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        Reports
      </Typography>

      <div style={{ marginBottom: "1rem", display: "flex", gap: "1rem" }}>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.startDate}
          onChange={(e) => handleFilterChange("startDate", e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.endDate}
          onChange={(e) => handleFilterChange("endDate", e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
        <Button variant="contained" color="secondary" onClick={exportToCSV}>
          Export CSV
        </Button>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => openConfirmDialog("bulkDelete", null)}
        disabled={selectedReports.length === 0}
        style={{ marginBottom: "1rem" }}
      >
        Delete Selected
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedReports.length > 0 &&
                    selectedReports.length < reports.length
                  }
                  checked={
                    reports.length > 0 && selectedReports.length === reports.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report) => (
              <TableRow key={report.id} hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedReports.includes(report.id)}
                    onChange={() => handleSelectReport(report.id)}
                  />
                </TableCell>
                <TableCell>{getCategoryName(report.category)}</TableCell>
                <TableCell>{new Date(report.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{report.amount}</TableCell>
                <TableCell>Successful</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => openConfirmDialog("delete", report.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog open={confirmDialog.open} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Reports;
