import client from "./api";
import { Estate } from "../types/Estate";

export const getEstate = async (estateId: number): Promise<Estate | null> => {
  try {
    const response = await client.get(`/api/estates/${estateId}`);
    console.log('estate fetch', response.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching estate details:', error);
    return null;
  }
};

export const getAllEstates = async (): Promise<Estate[] | null> => {
    try {
      const response = await client.get(`/api/estates`);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching estate details:', error);
      return null;
    }
  };