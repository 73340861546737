import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import { saveAs } from "file-saver";
import { getResidents } from "../../services/ResidentService";
import { Modal } from "../../components/Modal"; // Assuming the Modal component is in the components folder
import { Resident } from "../../types/Resident";

const ResidentManagement = () => {
  const [residents, setResidents] = useState<Resident[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedResidents, setSelectedResidents] = useState<number[]>([]);
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    action: null,
    targetId: null,
    message: "",
  });
  const [filters, setFilters] = useState({
    name: "",
    meterNumber: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [viewDialog, setViewDialog] = useState<{
    open: boolean;
    resident: Resident | null;
  }>({
    open: false,
    resident: null,
  });

  useEffect(() => {
    fetchResidentData();
  }, []);

  const fetchResidentData = async () => {
    setLoading(true);
    try {
      const response = await getResidents();
      if (response) {
        setResidents(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id: number) => {
    axios.delete(`/residents/${id}`).then(() => {
      setResidents(residents.filter((resident) => resident.id !== id));
    });
  };

  const handleBulkDelete = () => {
    axios
      .post("/residents/bulk-delete", { resident_ids: selectedResidents })
      .then(() => {
        setResidents(
          residents.filter(
            (resident) => !selectedResidents.includes(resident.id)
          )
        );
        setSelectedResidents([]);
      });
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      setSelectedResidents(residents.map((resident) => resident.id));
    } else {
      setSelectedResidents([]);
    }
  };

  const handleSelectResident = (id: number) => {
    setSelectedResidents((prev) =>
      prev.includes(id)
        ? prev.filter((residentId) => residentId !== id)
        : [...prev, id]
    );
  };

  const openConfirmDialog = (action: string, id: number | null = null) => {
    const message =
      action === "delete"
        ? "Are you sure you want to delete this resident?"
        : "Are you sure you want to delete all selected residents?";
    setConfirmDialog((prev: typeof confirmDialog) => ({
      ...prev,
      open: true,
      action,
      targetId: id,
      message,
    }));
  };

  const closeConfirmDialog = () => {
    setConfirmDialog({
      open: false,
      action: null,
      targetId: null,
      message: "",
    });
  };

  const handleConfirmAction = () => {
    const { action, targetId } = confirmDialog;
    if (action === "delete") {
      handleDelete(targetId);
    } else if (action === "bulkDelete") {
      handleBulkDelete();
    }
    closeConfirmDialog();
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  // const applyFilters = () => {
  //   // No need to fetch data from the backend, as filtering is done on the frontend
  // };

  const exportToCSV = () => {
    const csvHeaders = "Name,Email,Phone,Status,Estate,Join Date\n";
    const csvContent = residents
      .map(
        (resident) =>
          `${resident.name},${resident.email},${
            resident.estateusers?.phonenumber
          },${resident.estateusers?.status},${
            resident.estateusers?.estates?.name
          },${new Date(resident.created_at).toLocaleDateString()}`
      )
      .join("\n");

    const csvBlob = new Blob([csvHeaders + csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvBlob, "residents.csv");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openViewDialog = (resident: Resident) => {
    setViewDialog({ open: true, resident });
  };

  const closeViewDialog = () => {
    setViewDialog({ open: false, resident: null });
  };

  const filteredResidents = residents.filter((resident) => {
    const nameMatch = resident.name
      .toLowerCase()
      .includes(filters.name.toLowerCase());
    const meterNumberMatch = resident.estateusers?.meternumber
      ?.toLowerCase()
      .includes(filters.meterNumber.toLowerCase());
    return nameMatch && meterNumberMatch;
  });

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper elevation={3} style={{ padding: "1rem", margin: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        Resident Management
      </Typography>

      <div style={{ marginBottom: "1rem", display: "flex", gap: "1rem" }}>
        <TextField
          label="Name"
          value={filters.name}
          onChange={(e) => handleFilterChange("name", e.target.value)}
        />
        <TextField
          label="Meter Number"
          value={filters.meterNumber}
          onChange={(e) => handleFilterChange("meterNumber", e.target.value)}
        />
        {/* <Button variant="contained" color="primary" onClick={applyFilters}>
          Apply Filters
        </Button> */}
        <Button variant="contained" color="secondary" onClick={exportToCSV}>
          Export CSV
        </Button>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => openConfirmDialog("bulkDelete")}
        disabled={selectedResidents.length === 0}
        style={{ marginBottom: "1rem" }}
      >
        Delete Selected
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedResidents.length > 0 &&
                    selectedResidents.length < residents.length
                  }
                  checked={
                    residents.length > 0 &&
                    selectedResidents.length === residents.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Meter Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Estate</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredResidents
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((resident) => (
                <TableRow key={resident.id} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedResidents.includes(resident.id)}
                      onChange={() => handleSelectResident(resident.id)}
                    />
                  </TableCell>
                  <TableCell>{resident.name}</TableCell>
                  <TableCell>{resident.email}</TableCell>
                  <TableCell>{resident.estateusers?.meternumber}</TableCell>
                  <TableCell>{resident.estateusers?.status}</TableCell>
                  <TableCell>{resident.estateusers?.estates?.name}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => openViewDialog(resident)}
                      style={{ marginRight: "0.5rem" }}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => openConfirmDialog("delete", resident.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredResidents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {confirmDialog.open && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper style={{ padding: "1rem", width: "300px" }}>
            <Typography variant="h6">Confirm Action</Typography>
            <Typography>{confirmDialog.message}</Typography>
            <div
              style={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={closeConfirmDialog}
                color="secondary"
                style={{ marginRight: "0.5rem" }}
              >
                Cancel
              </Button>
              <Button onClick={handleConfirmAction} color="primary">
                Confirm
              </Button>
            </div>
          </Paper>
        </div>
      )}

      <Modal
        title="Resident Details"
        isVisible={viewDialog.open}
        onClose={closeViewDialog}
        modalData={viewDialog.resident}
      >
        <div style={{ padding: "1rem" }}>
          <Typography variant="h6">Resident Details</Typography>
          {viewDialog.resident && (
            <>
              <Typography>Name: {viewDialog.resident.name}</Typography>
              <Typography>Email: {viewDialog.resident.email}</Typography>
              <Typography>
                Phone: {viewDialog.resident.estateusers?.phonenumber}
              </Typography>
              <Typography>
                Status: {viewDialog.resident.estateusers?.status}
              </Typography>
              <Typography>
                Estate: {viewDialog.resident.estateusers?.estates?.name}
              </Typography>
              <Typography>
                Join Date:{" "}
                {new Date(viewDialog.resident.created_at).toLocaleDateString()}
              </Typography>
            </>
          )}
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={closeViewDialog} color="primary">
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </Paper>
  );
};

export default ResidentManagement;
