import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Typography,
  CircularProgress,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { saveAs } from "file-saver";
import { fetchPendingUsers } from "../../services/ResidentService";

const PendingApprovals = () => {
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [confirmDialog, setConfirmDialog] = useState<any>({
    open: false,
    action: null,
    targetId: null,
    message: "",
  });
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    fetchPendingApprovals();
  }, []);

  const fetchPendingApprovals = async () => {
    setLoading(true);
    try {
      const response = await fetchPendingUsers(
        filters.startDate,
        filters.endDate
      );
      if (response) {
        console.log("users found", response);
        setUsers(response);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = (id: number) => {
    axios.post(`/users/${id}/approve`).then(() => {
      setUsers(users.filter((user: any) => user.id !== id));
    });
  };

  const handleReject = (id: number) => {
    axios.post(`/users/${id}/reject`).then(() => {
      setUsers(users.filter((user: any) => user.id !== id));
    });
  };

  const handleBulkApprove = () => {
    axios.post("/users/bulk-approve", { user_ids: selectedUsers }).then(() => {
      setUsers(users.filter((user: any) => !selectedUsers.includes(user.id)));
      setSelectedUsers([]);
    });
  };

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      setSelectedUsers(users.map((user: any) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (id: number) => {
    setSelectedUsers((prev: number[]) =>
      prev.includes(id) ? prev.filter((userId) => userId !== id) : [...prev, id]
    );
  };

  const openConfirmDialog = (action: string, id = null) => {
    const message =
      action === "approve"
        ? "Are you sure you want to approve this user?"
        : action === "reject"
        ? "Are you sure you want to reject this user?"
        : "Are you sure you want to approve all selected users?";
    setConfirmDialog({ open: true, action, targetId: id, message });
  };

  const closeConfirmDialog = () => {
    setConfirmDialog({
      open: false,
      action: null,
      targetId: null,
      message: "",
    });
  };

  const handleConfirmAction = () => {
    const { action, targetId } = confirmDialog;
    if (action === "approve") {
      handleApprove(targetId);
    } else if (action === "reject") {
      handleReject(targetId);
    } else if (action === "bulkApprove") {
      handleBulkApprove();
    }
    closeConfirmDialog();
  };

  const handleFilterChange = (field: string, value: string) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  const applyFilters = () => {
    const { startDate, endDate } = filters;
    axios
      .get("/users?status=pending", { params: { startDate, endDate } })
      .then((response) => {
        setUsers(response.data);
      });
  };

  const exportToCSV = () => {
    const csvHeaders = "Name,Email,Signup Date\n";
    const csvContent = users
      .map(
        (user: {
          name: any;
          email: any;
          signup_date: string | number | Date;
        }) =>
          `${user.name},${user.email},${new Date(
            user.signup_date
          ).toLocaleDateString()}`
      )
      .join("\n");

    const csvBlob = new Blob([csvHeaders + csvContent], {
      type: "text/csv;charset=utf-8;",
    });

    saveAs(csvBlob, "pending_approvals.csv");
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Paper elevation={3} style={{ padding: "1rem", margin: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        Pending Approvals
      </Typography>

      <div style={{ marginBottom: "1rem", display: "flex", gap: "1rem" }}>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.startDate}
          onChange={(e) => handleFilterChange("startDate", e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={filters.endDate}
          onChange={(e) => handleFilterChange("endDate", e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={applyFilters}>
          Apply Filters
        </Button>
        <Button variant="contained" color="secondary" onClick={exportToCSV}>
          Export CSV
        </Button>
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => openConfirmDialog("bulkApprove")}
        disabled={selectedUsers.length === 0}
        style={{ marginBottom: "1rem" }}
      >
        Approve Selected
      </Button>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedUsers.length > 0 &&
                    selectedUsers.length < users.length
                  }
                  checked={
                    users.length > 0 && selectedUsers.length === users.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Signup Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: any) => (
              <TableRow key={user.id} hover>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleSelectUser(user.id)}
                  />
                </TableCell>
                <TableCell>{user.firstname + " " + user.lastname}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {new Date(user.created_at).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() => openConfirmDialog("approve", user.id)}
                    style={{ marginRight: "0.5rem" }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => openConfirmDialog("reject", user.id)}
                  >
                    Reject
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={confirmDialog.open} onClose={closeConfirmDialog}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDialog.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default PendingApprovals;
