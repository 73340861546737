import client from "./api";

/**
 * Fetch all transactions
 * @param page The page number to fetch
 * @param startDate The start date filter
 * @param endDate The end date filter
 * @returns Promise containing transaction data
 */
export const fetchAllTransactions = async (
  page: number = 1,
  startDate?: string,
  endDate?: string
): Promise<any> => {
  try {
    const response = await client.post('/api/payment/all', { page, startDate, endDate });
    console.log('fetch all transactions', response.data.data);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};
