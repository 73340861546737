import React from 'react';
import styled from 'styled-components';
import { Outlet, Link } from 'react-router-dom';
import { FaUsers, FaFileAlt, FaBuilding, FaRegistered } from 'react-icons/fa';

const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const Sidebar = styled.div`
  width: 250px;
  background-color: #1a1a2e;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SidebarLink = styled(Link)`
  text-decoration: none;
  color: white;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 4px;

  &:hover {
    background-color: #16213e;
  }
`;

const LogoutButton = styled.button`
  margin-top: auto;
  padding: 10px 15px;
  background-color: #e63946;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #d62828;
  }
`;

const ContentArea = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #f4f6f8;
  overflow-y: auto;
`;

const AdminDashboard: React.FC = () => {
  const name = "Admin"; // Replace with dynamic name if available

  const handleLogout = () => {
    // Implement logout logic
    console.log("Logout clicked");
  };

  return (
    <DashboardContainer>
      <Sidebar>
        <h2>Ressydent Admin</h2>
        <p>Hello, {name}</p>
        <SidebarLink to="residents">
          <FaUsers style={{ marginRight: '10px' }} />
          Resident Management
        </SidebarLink>
        <SidebarLink to="newusers">
          <FaRegistered style={{ marginRight: '10px' }} />
          New Users Management
        </SidebarLink>
        <SidebarLink to="reports">
          <FaFileAlt style={{ marginRight: '10px' }} />
          Reports
        </SidebarLink>
        <SidebarLink to="estates">
          <FaBuilding style={{ marginRight: '10px' }} />
          Estate Management
        </SidebarLink>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </Sidebar>
      <ContentArea>
        <Outlet />
      </ContentArea>
    </DashboardContainer>
  );
};

export default AdminDashboard;